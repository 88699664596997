import React from "react"
import { Link } from "gatsby"
import Date from "./Date"

function PostLink({ to, title, date, excerpt, ...props }) {
  return (
    <div
      {...props}
      className={`border border-tan-200 hover:border-tan-300 bg-white hover:shadow pt-16 px-24 pb-8 rounded-8 cursor-pointer group ${
        props.className ? props.className : ""
      }`}
    >
      <Link to={to}>
        <div className="md:flex md:justify-start space-y-16 md:space-y-0 md:space-x-16">
          <Date date={date} className="flex-grow-0" />

          <div>
            <div className="flex justify-start space-x-8">
              <h3 className="font-geared text-xl tracking-wide uppercase text-gray-400 leading-none group-hover:text-red-300">
                {title}
              </h3>

              <span className="font-geared text-xl tracking-wide uppercase text-red-200 leading-none group-hover:text-red-300">
                &gt;
              </span>
            </div>

            <p
              className="font-thin group-hover:text-red-300"
              dangerouslySetInnerHTML={{
                __html: excerpt,
              }}
            />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PostLink
